exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-claim-peach-js": () => import("./../../../src/pages/claimPeach.js" /* webpackChunkName: "component---src-pages-claim-peach-js" */),
  "component---src-pages-crews-js": () => import("./../../../src/pages/crews.js" /* webpackChunkName: "component---src-pages-crews-js" */),
  "component---src-pages-farm-house-create-js": () => import("./../../../src/pages/farmHouse/create.js" /* webpackChunkName: "component---src-pages-farm-house-create-js" */),
  "component---src-pages-farm-house-index-js": () => import("./../../../src/pages/farmHouse/index.js" /* webpackChunkName: "component---src-pages-farm-house-index-js" */),
  "component---src-pages-farm-house-view-js": () => import("./../../../src/pages/farmHouse/view.js" /* webpackChunkName: "component---src-pages-farm-house-view-js" */),
  "component---src-pages-harvesting-js": () => import("./../../../src/pages/harvesting.js" /* webpackChunkName: "component---src-pages-harvesting-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-hospital-js": () => import("./../../../src/pages/hospital.js" /* webpackChunkName: "component---src-pages-hospital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inventory-tractor-js": () => import("./../../../src/pages/inventoryTractor.js" /* webpackChunkName: "component---src-pages-inventory-tractor-js" */),
  "component---src-pages-inventory-waifu-js": () => import("./../../../src/pages/inventoryWaifu.js" /* webpackChunkName: "component---src-pages-inventory-waifu-js" */),
  "component---src-pages-market-tractor-js": () => import("./../../../src/pages/marketTractor.js" /* webpackChunkName: "component---src-pages-market-tractor-js" */),
  "component---src-pages-market-waifu-js": () => import("./../../../src/pages/marketWaifu.js" /* webpackChunkName: "component---src-pages-market-waifu-js" */),
  "component---src-pages-mechanic-js": () => import("./../../../src/pages/mechanic.js" /* webpackChunkName: "component---src-pages-mechanic-js" */),
  "component---src-pages-mint-js": () => import("./../../../src/pages/mint.js" /* webpackChunkName: "component---src-pages-mint-js" */)
}

