import React from "react"
import { Web3ReactProvider } from "@web3-react/core"
import Web3 from "web3"
import UserState from "./src/context/userContext/UserState"

function getLibrary(provider) {
    return new Web3(provider)
}

// highlight-start
export const wrapRootElement = ({ element }) => (
    <Web3ReactProvider getLibrary={getLibrary}>
        <UserState>
            {element}
        </UserState>
    </Web3ReactProvider>
)
