export const CHANGE_MINT = "CHANGE_MINT"
export const CHANGE_MENU = "CHANGE_MENU"
export const CHANGE_ORACLE = "CHANGE_ORACLE"
export const CHANGE_CONTRACT = "CHANGE_CONTRACT"
export const CHANGE_MENUOPTION = "CHANGE_MENUOPTION"
export const CHANGE_MINTOPTION = "CHANGE_MINTOPTION"
export const CHANGE_WAIFUS = "CHANGE_WAIFUS"
export const CHANGE_TOPDATA = "CHANGE_TOPDATA"
export const CHANGE_HARVEST = "CHANGE_HARVEST"
export const CHANGE_MODAL = "CHANGE_MODAL"
export const CHANGE_MODAL_BG = "CHANGE_MODAL_BG"
export const CHANGE_SECTION = "CHANGE_SECTION"
export const CHANGE_CURRENTPRICE = "CHANGE_CURRENTPRICE"