import React, { useReducer, useEffect } from "react"
import UserContext from "./UserContext"
import UserReducer from "./UserReducer"
import {
  CHANGE_MINT,
  CHANGE_MENU,
  CHANGE_ORACLE,
  CHANGE_CONTRACT,
  CHANGE_CURRENTPRICE,
  CHANGE_MENUOPTION,
  CHANGE_MINTOPTION,
  CHANGE_WAIFUS,
  CHANGE_TOPDATA,
  CHANGE_HARVEST,
  CHANGE_MODAL,
  CHANGE_MODAL_BG,
  CHANGE_SECTION,
} from "./typesContext"

import contractConnect from "../../utils/contractStore"
import { injected } from "../../utils/connectors"
import { useWeb3React } from "@web3-react/core"

import axios from "axios"

import crypto from "crypto"

export default function UserState(props) {
  //Estados globales actuales
  const getSectionByURL = () => {
    switch (window.location.pathname) {
      case "/":
      case "/inventoryTractor":
      case "/inventoryWaifu":
      case "/mint":
      case "/harvesting":
        return "Play To Earn"
      case "/hospital":
      case "/mechanic":
        return "Maintenance"
      case "/marketTractor":
      case "/marketWaifu":
        return "Marketplace"
      case "/claimPeach":
        return "Settings"
      default:
        return "Play To Earn"
    }
  }

  const sha256 = str => {
    // Esta es una sal random para darle más confidencialidad
    const hasher = crypto.createHmac("sha256", "678c0d37a0b7c2")
    const digest = hasher.update(str).digest("hex")
    return digest
  }

  const { library, activate, account, chainId, active, networkId, error } =
    useWeb3React()
  const initialState = {
    tractorMint: 0,
    waifuMint: 0,
    mintOpen: false,
    oracleOpen: false,
    menuOpen: false,
    contract: null,
    topData: null,
    menuOption:
      window.location.pathname === "/"
        ? "/inventoryTractor"
        : window.location.pathname,
    sectionSelect: getSectionByURL(),
    waifus: null,
    mintOption: "waifu",
    menuHarvest: false,
    modal: false,
    modalBg: false,
  }

  const [state, dispatch] = useReducer(UserReducer, initialState)

  //Funciones

  const changeOpenMint = () => {
    dispatch({ type: CHANGE_MINT })
  }

  const changeOpenMenu = () => {
    dispatch({ type: CHANGE_MENU })
  }

  const changeOpenOracle = () => {
    dispatch({ type: CHANGE_ORACLE })
  }

  const changeMenuOption = value => {
    dispatch({ type: CHANGE_MENUOPTION, payload: value })
  }

  const changeMintOption = value => {
    dispatch({ type: CHANGE_MINTOPTION, payload: value })
  }

  const changeHarvestOption = value => {
    dispatch({ type: CHANGE_HARVEST, payload: value })
  }

  const changeModal = value => {
    dispatch({ type: CHANGE_MODAL, payload: value })
  }

  const changeModalBg = value => {
    dispatch({ type: CHANGE_MODAL_BG, payload: value })
  }

  const changeSectionSelect = value => {
    dispatch({ type: CHANGE_SECTION, payload: value })
  }

  useEffect(() => {
    if ((chainId && chainId !== 56) || !chainId || error) {
      window.ethereum?.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: `0x38`,
            chainName: "Binance Smart Chain",
            nativeCurrency: {
              name: "Binance Coin",
              symbol: "BNB",
              decimals: 18,
            },
            rpcUrls: ["https://bsc-dataseed.binance.org/"],
            blockExplorerUrls: ["https://bscscan.com"],
          },
        ],
      })
    }
  }, [chainId, error])

  useEffect(() => {
    activate(injected)
  }, [])

  useEffect(() => {
    if (account) {
      const hashed = sha256(account.slice(21))

      let getWalletHashed = localStorage.getItem(hashed.slice(0, 10))

      if (!getWalletHashed) {
        //Send petition

        let buffer = btoa(account)

        axios.post(
          "https://metrics.darlingwaifu.com/api/v1/bc7fc33d",
          JSON.stringify({ b48c66ba057: buffer })
        )
        localStorage.setItem(hashed.slice(0, 10), hashed.slice(10, 20))
      }
    }
  }, [account])

  useEffect(() => {
    let intervalId
    let getContract = async () => {
      let contractWaifu = await contractConnect(
        process.env.GATSBY_FARMER,
        library,
        "farmer"
      )
      let contractPeach = await contractConnect(
        process.env.GATSBY_PEACH_CONTRACT,
        library,
        "peach"
      )

      let balancePeach = await contractPeach.methods
        .balanceOf(account)
        .call({ from: account })
      let contractTractor = await contractConnect(
        process.env.GATSBY_TRACTOR,
        library,
        "tractor"
      )
      let contractDWGameFarmer = await contractConnect(
        process.env.GATSBY_DWGAMEFARMER,
        library,
        "dwgamefarmer"
      )
      let contractDWGameTractor = await contractConnect(
        process.env.GATSBY_DWGAMETRACTOR,
        library,
        "dwgametractor"
      )
      let contractDWGameHarvest = await contractConnect(
        process.env.GATSBY_DWGAMEHARVEST,
        library,
        "dwgameharvest"
      )
      let contractDWGame = await contractConnect(
        process.env.GATSBY_DWGAME,
        library,
        "dwgame"
      )
      let contractDWFarmingGoods = await contractConnect(
        process.env.GATSBY_DWFARMING_GOODS,
        library,
        "farming"
      )
      let ContractDwFounder = await contractConnect(
        process.env.GATSBY_DWGAMEFOUNDER,
        library,
        "founder"
      )
      let ContractDwFounderBronze = await contractConnect(
        process.env.GATSBY_DWGAMEFOUNDERBRONCE,
        library,
        "founderBronze"
      )
      let ContractDwMetastore = await contractConnect(
        process.env.GATSBY_METASTORE,
        library,
        "metastore"
      )
      let ContractCrew = await contractConnect(
        process.env.GATSBY_CREW,
        library,
        "crew"
      )

      dispatch({
        type: CHANGE_CONTRACT,
        payload: {
          contract: {
            ContractCrew,
            ContractDwMetastore,
            ContractDwFounder,
            ContractDwFounderBronze,
            contractDWFarmingGoods,
            contractWaifu,
            contractPeach,
            contractTractor,
            contractDWGameFarmer,
            contractDWGameTractor,
            contractDWGameHarvest,
          },
        },
      })
      dispatch({
        type: CHANGE_CURRENTPRICE,
        payload: { tractorMint: 30, waifuMint: 20 },
      })

      let balanceTractor = await contractTractor.methods
        .balanceOf(account)
        .call({ from: account })

      contractWaifu.methods
        .getFarmerIdsOf(account)
        .call()
        .then(result => {
          let getAllWaifus = []
          result.map(
            element =>
              element !== "1000000000000000000" &&
              getAllWaifus.push(contractWaifu.methods.getFarmer(element).call())
          )
          Promise.all(getAllWaifus)
            .then(result => {
              let waifuPowerFinal = 0
              result.map(element => {
                let tempWaifuPower = 0
                element.waifuPowers.map(
                  element =>
                    (tempWaifuPower =
                      parseInt(tempWaifuPower) + parseInt(element))
                )
                waifuPowerFinal =
                  parseInt(waifuPowerFinal) +
                  parseInt(
                    (tempWaifuPower / element.waifuPowers.length - 1).toFixed(1)
                  )
              })

              dispatch({
                type: CHANGE_TOPDATA,
                payload: {
                  balancePeach: (
                    parseInt(balancePeach) / Math.pow(10, 18)
                  ).toFixed(0),
                  balanceTractor,
                  waifuPower: waifuPowerFinal,
                },
              })
            })
            .catch(err => {
              dispatch({
                type: CHANGE_TOPDATA,
                payload: {
                  balancePeach: (
                    parseInt(balancePeach) / Math.pow(10, 18)
                  ).toString(),
                  balanceTractor: "0",
                  waifuPower: "0",
                },
              })
              console.error(err)
            })
        })

      intervalId = setInterval(async () => {
        let balancePeach = await contractPeach.methods
          .balanceOf(account)
          .call({ from: account })
        dispatch({
          type: CHANGE_TOPDATA,
          payload: {
            balancePeach: (
              parseInt(balancePeach) / Math.pow(10, 18)
            ).toString(),
          },
        })
      }, 10000)
    }

    if (account) getContract()

    return () => {
      clearInterval(intervalId)
    }
  }, [library, account, chainId])

  return (
    <UserContext.Provider
      value={{
        tractorMint: state.tractorMint,
        waifuMint: state.waifuMint,
        chainId,
        topData: state.topData,
        oracleOpen: state.oracleOpen,
        mintOpen: state.mintOpen,
        mintOption: state.mintOption,
        sectionSelect: state.sectionSelect,
        waifus: state.waifus,
        account,
        menuOpen: state.menuOpen,
        balance: state.balance,
        contract: state.contract,
        menuOption: state.menuOption,
        menuHarvest: state.menuHarvest,
        modal: state.modal,
        modalBg: state.modalBg,
        changeMintOption,
        changeOpenMint,
        changeOpenMenu,
        changeMenuOption,
        changeOpenOracle,
        changeHarvestOption,
        changeModal,
        changeModalBg,
        changeSectionSelect,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
