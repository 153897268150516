import { CHANGE_MINT, CHANGE_MENU, CHANGE_ORACLE, CHANGE_CURRENTPRICE, CHANGE_CONTRACT, CHANGE_MENUOPTION, CHANGE_MINTOPTION, CHANGE_WAIFUS, CHANGE_TOPDATA, CHANGE_HARVEST, CHANGE_MODAL, CHANGE_MODAL_BG, CHANGE_SECTION } from "./typesContext";

//ACCIONES QUE SE EJECUTAN CON UN DISPATCH

const UserReducer = (state, action) => {
  switch (action.type) {

    case CHANGE_MINT: return { ...state, mintOpen: !state.mintOpen }
    case CHANGE_MENU: return { ...state, menuOpen: !state.menuOpen }
    case CHANGE_ORACLE: return { ...state, oracleOpen: !state.oracleOpen }
    case CHANGE_CONTRACT: return { ...state, contract: action.payload.contract, }
    case CHANGE_MENUOPTION: return { ...state, menuOption: action.payload }
    case CHANGE_MINTOPTION: return { ...state, mintOption: action.payload }
    case CHANGE_WAIFUS: return { ...state, waifus: action.payload }
    case CHANGE_TOPDATA: return { ...state, topData: { ...state.topData, ...action.payload } }
    case CHANGE_HARVEST: return { ...state, menuHarvest: !state.menuHarvest }
    case CHANGE_MODAL: return { ...state, modal: action.payload }
    case CHANGE_MODAL_BG: return { ...state, modalBg: action.payload }
    case CHANGE_SECTION: return { ...state, sectionSelect: action.payload }
    case CHANGE_CURRENTPRICE: return { ...state, ...action.payload }
    default:
      return state;
  }
};

export default UserReducer;
